
/*New screen*/


.hidden {
  display: none;
}

body {
  font: 16px/1.3 'Muli', Helvetica, sans-serif!important;
  color: #646464;
  font-weight: 200;
  background-color: #fffdfd;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: sans-serif;
  word-wrap: break-word;
  text-align: center;
}

.wrapper {
  position: relative;
  top: 40%;
  max-width: 600px;
  margin: 0 auto;
  transform: translateY(-42%);
}


h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  font-weight: 200;
}

a {
  color: #646464;
}


.h1 {
  margin-bottom: 50px;
  font-size: 42px;
  letter-spacing: -.5;
  text-align: center;
}

.main_p {
  font-weight: 200;
  text-align: center; 
  margin-bottom: 40px;
}

.light {
  display: inline-block;
  margin-top: -2px;
  color: #a9a9a9;
}

.check_btn {
  display: inline-block;
  margin-bottom: 70px;
  padding: 15px 63px;
  font-size: 26px;
  text-decoration: none;
  color: #fff;
  font-weight: 100;
  background-color: #0f95ef;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
  &:hover {
    background-color: lighten(#0f95ef, 5%);
    -webkit-box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2);
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2);
}
  
}


.policy_block {
  color: #a9a9a9;
  font-weight: 200;
}




.chrome-arrow {
    position: absolute;
    top: 280px;
    left: 48%;
}

.oc-arrow {
    position: absolute;
    height: 103px;
    left: 100px;
    top: -75px;
}

.oc-extension-icon {
  width: 40px;
}

.newarrow {
  display: inline-block;
  position: relative;
  top: 3px;
  left: -10px;
}

.oc-chrome-cancel-modal {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .5);
}

.oc-chrome-cancel-modal .oc-modal-container {
    width: 430px;
    height: 185px;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-left: -225px;
    background: #fff url(img/cancel-modal.jpg) no-repeat;
    box-shadow: 0px 0px 3px rgba(0,0,0,.35);
}

.oc-chrome-cancel-modal .oc-extension-icon {
    position: absolute;
    top: 26px;
    right: 27px;
}

.oc-chrome-cancel-modal button {
    cursor: pointer;
    bottom: 30px;
    position: absolute;
    width: 80px;
    text-align: center;
    font-size: 15px;
    padding: 5px 0;
}

.oc-chrome-cancel-modal .oc-yes {right: 122px;}
.oc-chrome-cancel-modal .oc-no {right: 27px;}

.oc-extension-directions-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2001;
    text-align: center;
}

.oc-extension-directions-overlay .oc-chrome-overlay-new {
    top: 50%;
    left: 50.5%;
    padding-right: 80px;
    border-radius: 5px;
    text-align: left;
    transform: translate(-50%, 35%);
}

.oc-extension-directions-overlay div {
    position: absolute;
    background: #fff;
    padding: 20px;
    color: black;
    border-radius: 10px;
    font-size: 22px;
    font-family: Helvetica, Arial, Sans-Serif;
}

.oc-extension-directions-overlay .oc-chrome-overlay-new h6 {
    font-size: 22px;
    margin-top: 8px;
    margin-bottom: 16px;
}

.oc-extension-directions-overlay .oc-chrome-overlay-new .oc-extension-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    max-width: 55px;
}

.oc-extension-directions-overlay .oc-chrome-overlay-new p {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
}

.oc-extension-directions-overlay .oc-chrome-overlay-new strong {font-weight: 600;}


/*Arrow animation*/
.bounce-up-down{
  animation: animationFrames linear 1.5s;
  -webkit-animation: animationFrames linear 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  webkit-transform-origin: 50% 50%;
}
@keyframes animationFrames{
  0% {
  transform:  translate(0px,0px)  ;
  }
  15% {
  transform:  translate(0px,-25px)  ;
  }
  30% {
  transform:  translate(0px,0px)  ;
  }
  45% {
  transform:  translate(0px,-15px)  ;
  }
  60% {
  transform:  translate(0px,0px)  ;
  }
  75% {
  transform:  translate(0px,-5px)  ;
  }
  100% {
  transform:  translate(0px,0px)  ;
  }
}
@-webkit-keyframes animationFrames {
  0% {
  -webkit-transform:  translate(0px,0px)  ;
  }
  15% {
  -webkit-transform:  translate(0px,-25px)  ;
  }
  30% {
  -webkit-transform:  translate(0px,0px)  ;
  }
  45% {
  -webkit-transform:  translate(0px,-15px)  ;
  }
  60% {
  -webkit-transform:  translate(0px,0px)  ;
  }
  75% {
  -webkit-transform:  translate(0px,-5px)  ;
  }
  100% {
  -webkit-transform:  translate(0px,0px)  ;
  }
}





